import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import Home from './ui/Home';
import Books from './ui/Books';
import User from './ui/User';
import Fooder from './ui/Fooder';
import PayInfoModal from './modals/PayInfoModal';

import { db } from '../config';
import FooderControl from './components/FooderControl';

function Main() {
    const [activeTab, setActiveTab] = useState('home'); // Белсенді қойындыны сақтау үшін күй
    const [audioList, setAudioList] = useState([]); // Аудио тізімі
    const [currentAudioIndex, setCurrentAudioIndex] = useState(0); // Ағымдағы аудио индексі
    const [isAudioPlayerVisible, setAudioPlayerVisible] = useState(false); // Аудио плеер көрінетінін тексеру
    const [isPlaying, setIsPlaying] = useState(true); // Аудио ойнату күйі
    const [isModalVisible, setIsModalVisible] = useState(false); // Модальді көріну күйі
    const [author, setAuthor] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [bookId, setBookId] = useState(null);
    const userPhotoUrl = JSON.parse(localStorage.getItem('user'))?.photoUrl;
    const userId = JSON.parse(localStorage.getItem('user'))?.uid;

    useEffect(() => {
        const checkUserInfo = async () => {

            if (userId) {
                try {
                    const userDoc = await getDoc(doc(db, 'users', userId));
                    const userData = userDoc.exists() ? userDoc.data() : {};
                    const phoneNumber = userData.phoneNumber || '';
                    const cardData = localStorage.getItem('cardData') || '';

                    if (!phoneNumber && !cardData) {
                        setIsModalVisible(true);
                    } else {
                        setIsModalVisible(false);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                console.log('User is not authenticated');
            }
        };

        checkUserInfo();
    }, [userId]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handlePlayAudio = (id, audioUrls, audioNames, author, imageUrl) => {
        console.log(imageUrl)
        setBookId(id)
        setAudioList(audioUrls.map((url, index) => ({ url, name: audioNames[index] })));
        setCurrentAudioIndex(0);
        setAudioPlayerVisible(true);
        setIsPlaying(true);
        setAuthor(author);
        setImageUrl(imageUrl);
    };

    const handleNextAudio = () => {
        setCurrentAudioIndex((prevIndex) => (prevIndex + 1) % audioList.length);
        setIsPlaying(true);
    };

    const handlePrevAudio = () => {
        setCurrentAudioIndex((prevIndex) =>
            prevIndex === 0 ? audioList.length - 1 : prevIndex - 1
        );
        setIsPlaying(true);
    };

    const togglePlay = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'home':
                return <Home handlePlayAudio={handlePlayAudio} handleReadButtonClick={() => {
                    setIsPlaying(false);
                    if ('mediaSession' in navigator) {
                        navigator.mediaSession.metadata = null;
                    }
                }}
                />;
            case 'books':
                return <Books handleReadButtonClick={() => {
                    setIsPlaying(false);
                    if ('mediaSession' in navigator) {
                        navigator.mediaSession.metadata = null;
                    }
                }}
                />;
            case 'user':
                return <User />;
            default:
                return <Home handleReadButtonClick={() => {
                    setIsPlaying(false);
                    if ('mediaSession' in navigator) {
                        navigator.mediaSession.metadata = null;
                    }
                }}
                />;
        }
    };

    return (
        <div className="main_container">
            {renderContent()}
            <Fooder
                activeTab={activeTab}
                userPicture={userPhotoUrl}
                handleTabChange={handleTabChange}
            />
            {isAudioPlayerVisible && (
                <FooderControl
                    userId={userId}
                    id={bookId}
                    audioUrl={audioList[currentAudioIndex]?.url}
                    audioName={audioList[currentAudioIndex]?.name}
                    author={author}
                    imageUrl={imageUrl}
                    onNext={handleNextAudio}
                    onPrev={handlePrevAudio}
                    isPlaying={isPlaying}
                    onPlay={togglePlay}
                />
            )}
            {isModalVisible && (
                <PayInfoModal
                    isOpen={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                />
            )}
        </div>
    );
}

export default Main;