import { useEffect } from 'react';

const useMediaSession = ({ title, artist, artwork, onPlay, onPause, onNext, onPrev }) => {
    useEffect(() => {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title,
                artist,
                artwork,
            });

            navigator.mediaSession.setActionHandler('play', onPlay);
            navigator.mediaSession.setActionHandler('pause', onPause);
            navigator.mediaSession.setActionHandler('nexttrack', onNext);
            navigator.mediaSession.setActionHandler('previoustrack', onPrev);
        }
    }, [title, artist, artwork, onPlay, onPause, onNext, onPrev]);
};

export default useMediaSession;