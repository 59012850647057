import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/modal_sign.css';
import '../../styles/pay_modal.css';
import CryptoJS from 'crypto-js';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth, realtimeDb } from '../../config';
import { ref, query, orderByChild, equalTo, get, push, set } from 'firebase/database';
import ModalSuccess from './ModalSuccess';

const BuyModal = ({ isOpen, onClose, bookId, bookName, bookPrice, bookDescription, bookAudioCount }) => {
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [phoneNumber, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const userId = JSON.parse(localStorage.getItem('user'))?.uid;
    const [agree, setAgree] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        const checkUserInfo = async () => {
            if (userId) {
                try {
                    const userDoc = await getDoc(doc(db, 'users', userId));
                    const userData = userDoc.exists() ? userDoc.data() : {};
                    const savedPhoneNumber = userData.phoneNumber || '';
                    const cardData = localStorage.getItem('cardData') || '';
                    setNumber(savedPhoneNumber);

                    if (!savedPhoneNumber && !cardData) {
                        setIsModalVisible(true);
                        setModalMessage('Сізде ешбір төлем жолы жасалмаған');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                console.log('User is not authenticated');
            }
        };

        checkUserInfo();
    }, [userId]);

    const handleTabClick = (tab) => {
        setIsLogin(tab === 'kaspi');
        if (tab === 'kaspi' && !phoneNumber) {
            setModalMessage('Сізде Kaspi төлем жолы жасалмаған');
        } else if (tab === 'card' && !cardNumber) {
            setModalMessage('Сізде Карта төлем жолы жасалмаған');
        }
    };

    const handleBuyByNumber = async () => {
        if (!agree) {
            setErrorMessage('KaspiGold шарттарын қабылдауыңыз керек.');
            return;
        }

        if (!phoneNumber) {
            setErrorMessage('KaspiGold нөмірін енгізіңіз.');
            return;
        }

        if (!bookId) {
            setErrorMessage('Кітап ID табылмады. Қайта көріңіз.');
            console.log("error")
            return;
        }

        setIsProcessing(true);

        try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('Пайдаланушы анықталмады.');
            }

            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();

                const ordersRef = ref(realtimeDb, 'orders');
                const ordersQuery = query(ordersRef, orderByChild('bookId'), equalTo(bookId));
                const ordersSnapshot = await get(ordersQuery);

                let hasOrderedSameBook = false;

                ordersSnapshot.forEach((childSnapshot) => {
                    if (childSnapshot.val().currentUserId === user.uid) {
                        hasOrderedSameBook = true;
                    }
                });

                if (hasOrderedSameBook) {
                    setModalMessage('Сіздің өтінім қаралып жатыр');
                } else {
                    const orderData = {
                        bookId: bookId,
                        currentUserId: user.uid,
                        number: userData?.phoneNumber || '',
                        bookName: bookName,
                        displayName: user.displayName || '',
                        timestamp: Date.now(),
                        status: 'pending',
                        price: bookPrice,
                    };

                    const newOrderRef = push(ordersRef);
                    await set(newOrderRef, orderData);

                    setModalMessage('Аудио кітапты төлем жасағаннан кейін тыңдай аласыңыз');
                }
                setIsModalVisible(true);
            } else {
                alert('User data not found!');
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Төлем өңделгенде қате пайда болды. Қайтадан байқап көріңіз.');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleBuyByCard = (e) => {
        e.preventDefault();

        if (!cardNumber || !expiryDate || !cvv) {
            setErrorMessage('Карта мәліметтері енгізіңіз.');
            return;
        }

        try {
            const cardData = { cardNumber, expiryDate, cvv };
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(cardData), userId).toString();
            if (encryptedData) {
                localStorage.setItem('cardData', encryptedData);
                setMessage('Карта мәліметтері сәтті сақталды.');
                setErrorMessage('');
                onClose();
            }
        } catch (error) {
            setErrorMessage('Карта мәліметтерін сақтау кезінде қате пайда болды.');
            setMessage('');
        }
    };

    const decryptData = useCallback(() => {
        const encryptedData = localStorage.getItem('cardData');
        if (!encryptedData || !userId) {
            setErrorMessage('Карта мәліметтірін табу мүмкін болмады');
            return null;
        }
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, userId);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedData;
        } catch (error) {
            setErrorMessage('Деректерді дешифрлау кезінде қате пайда болды.');
            return null;
        }
    }, [userId]);

    useEffect(() => {
        if (!isLogin) {
            const decryptedCardData = decryptData();
            if (decryptedCardData) {
                setCardNumber(decryptedCardData.cardNumber || '');
                setExpiryDate(decryptedCardData.expiryDate || '');
                setCvv(decryptedCardData.cvv || '');
            }
        }
    }, [decryptData, isLogin]);

    return (
        isOpen && (
            <div className="pay_modal">
                <div className='pay_modal_content'>
                    <p className="close-modal" onClick={onClose}>&times;</p>
                    {isModalVisible && <ModalSuccess message={modalMessage} onClose={() => setIsModalVisible(false)} />}
                    <div className="form-container">
                        <div className="slide-controls">
                            <input type="radio" name="slide" id="login" checked={isLogin} readOnly />
                            <input type="radio" name="slide" id="signup" checked={!isLogin} readOnly />
                            <label htmlFor="login" className="slide login" onClick={() => handleTabClick('kaspi')}>
                                Kaspi
                            </label>
                            <label htmlFor="signup" className="slide signup" onClick={() => handleTabClick('card')}>
                                Карта
                            </label>
                            <div className="slider-tab"></div>
                        </div>
                        <div>
                            {isLogin ? (
                                <div className="pay_modal_overlay">
                                    <p className="pay_modal_text">{bookName}</p>
                                    <p className="pay_modal_text">Аудиолар саны: {bookAudioCount}</p>
                                    <p className="pay_modal_text">Құны: {bookPrice}</p>
                                    <p className="pay_modal_text">Сипаттама: {bookDescription}</p>

                                    <div className="pay_check_content">
                                        <p className="pay_text">
                                            KaspiGold төлемді модератор қарайды сондықтан күтуіңіз керек
                                        </p>
                                        <div className="pay_checkbox_container">
                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={agree}
                                                onChange={() => setAgree(!agree)}
                                            />
                                            <label htmlFor="agree">
                                                <svg className="pay_check_icon" viewBox="0 0 24 24">
                                                    <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>

                                    <button className="save_button" onClick={handleBuyByNumber} disabled={!agree || isProcessing}>
                                        {isProcessing ? 'Өңдеу...' : 'Сатып алу'}
                                    </button>
                                </div>
                            ) : (
                                <form onSubmit={handleBuyByCard} className="pay_modal_overlay">
                                    <p className="pay_modal_text">{bookName}</p>
                                    <p className="pay_modal_text">Аудиолар саны: {bookAudioCount}</p>
                                    <p className="pay_modal_text">Құны: {bookPrice}</p>
                                    <p className="pay_modal_text">Сипаттама: {bookDescription}</p>
                                    <button type="submit" className="save_button">Сатып алу</button>
                                </form>
                            )}
                            {errorMessage && <div className="error">{errorMessage}</div>}
                            {message && <div className="success">{message}</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default BuyModal;