import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { ref, get } from 'firebase/database';
import { db, auth, realtimeDb } from '../../config';

// components
import HomeUI from '../contents/HomeUI';
import BuyModal from '../modals/BuyModal';

// styles
import '../../styles/home.css';

const Home = ({ handlePlayAudio, handleReadButtonClick }) => {
    const [newBooks, setNewBooks] = useState([]);
    const [newSongs, setNewSongs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userBooks, setUserBooks] = useState([]);
    const [lastBooks, setLastBooks] = useState([]);
    const [isPayModalOpen, setIsPayModalOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState({});
    const user = auth.currentUser;

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const fetchData = async () => {
                    try {
                        const userRef = ref(realtimeDb, `users/${user.uid}/last_played`);
                        const snapshot = await get(userRef); // Get data from the ref
                        const lastPlayedBooks = snapshot.exists() ? snapshot.val() : [];

                        const lastPlayedBookData = await Promise.all(
                            lastPlayedBooks.map(async (bookId) => {
                                const bookDocRef = doc(db, 'books', bookId);
                                const bookDocSnap = await getDoc(bookDocRef);
                                if (bookDocSnap.exists()) {
                                    return { id: bookId, ...bookDocSnap.data() };
                                }
                                return null;
                            })
                        );
                        
                        const nonNullLastPlayedBooks = lastPlayedBookData.filter(book => book !== null);
                        setLastBooks(nonNullLastPlayedBooks);

                        const cachedNewBooks = localStorage.getItem('newBooks');
                        const cachedSongs = localStorage.getItem('newSongs');

                        const shouldFetchFreshData = () => {
                            const cacheTimestamp = localStorage.getItem('cacheTimestamp');
                            const oneHour = 60 * 60 * 1000;
                            return !cacheTimestamp || (Date.now() - parseInt(cacheTimestamp, 10)) > oneHour;
                        };

                        if (cachedNewBooks && cachedSongs && !shouldFetchFreshData()) {
                            setNewBooks(JSON.parse(cachedNewBooks));
                            setNewSongs(JSON.parse(cachedSongs));
                            setLoading(false);
                        } else {
                            const allBooksData = [];
                            const allSongsData = [];
                            const querySnapshot = await getDocs(collection(db, 'books'));

                            querySnapshot.forEach((doc) => {
                                const bookData = doc.data();
                                if (bookData.type === 'bookAudio') {
                                    allBooksData.push({
                                        id: doc.id,
                                        title: bookData.title,
                                        imageUrl: bookData.imageUrl,
                                        type: bookData.type,
                                        audioUrls: bookData.audioUrls,
                                        description: bookData.description,
                                        author: bookData.author,
                                        audioFileNames: bookData.audioFileNames || [],
                                        price: bookData.price,
                                        isPaid: bookData.isPaid
                                    });
                                } else {
                                    allSongsData.push({
                                        id: doc.id,
                                        title: bookData.title,
                                        imageUrl: bookData.imageUrl,
                                        artist: bookData.artist,
                                        type: bookData.type,
                                        audioUrls: bookData.audioUrls,
                                        description: bookData.description,
                                        author: bookData.author,
                                        audioFileNames: bookData.audioFileNames || [],
                                        price: bookData.price
                                    });
                                }
                            });

                            setNewBooks(allBooksData);
                            setNewSongs(allSongsData);
                            localStorage.setItem('newBooks', JSON.stringify(allBooksData));
                            localStorage.setItem('newSongs', JSON.stringify(allSongsData));
                            localStorage.setItem('cacheTimestamp', Date.now().toString());
                            setLoading(false);
                        }
                    } catch (error) {
                        console.error('Error fetching data: ', error);
                    }
                };

                fetchData();
            } else {
                console.error("User not authenticated.");
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setUserBooks(userData.books || []);
                }
            }
        });

        return () => unsubscribe();
    }, []);

    const handleCheckPlay = async (bookId, audioUrls, audioFileNames, isPaid, bookTitle, bookDescription, bookPrice, author, imageUrl) => {
        const user = auth.currentUser;
        if (user) {
            if (userBooks.includes(bookId) || !isPaid) {
                handlePlayAudio(bookId, audioUrls, audioFileNames, author, imageUrl);
            } else {
                setSelectedBook({
                    bookId,
                    bookTitle,
                    bookDescription,
                    bookPrice,
                    audioFileNames
                });
                setIsPayModalOpen(true);
            }
        } else {
            navigate('/login');
        }
    };

    return (
        <>
            <HomeUI loading={loading} newBooks={newBooks} newSongs={newSongs} lastBooks={lastBooks} handleCheckPlay={handleCheckPlay} userBooks={userBooks} user={user} handlePlayAudio={handlePlayAudio} handleReadButtonClick={handleReadButtonClick}></HomeUI>
            <BuyModal
                isOpen={isPayModalOpen}
                onClose={() => setIsPayModalOpen(false)}
                bookId={selectedBook.bookId}
                bookName={selectedBook.bookTitle}
                bookPrice={selectedBook.bookPrice}
                bookDescription={selectedBook.bookDescription || ""}
                bookAudioCount={selectedBook.audioFileNames ? selectedBook.audioFileNames.length : 0}
            />
        </>
    );
};

export default Home;