import React from 'react';

// styles
import '../../styles/user.css';

// icons
import menuIcon from '../../icons/menu.svg';
import userAltIcon from '../../icons/profile.png';

// components
import BooksCard from '../items/BooksCard';
import Read from '../ui/Read';

export function UserUI(props) {
    return (
        <div className={`home-container`}>
            {
                props.isMenuOpen &&
                <div className="menu">
                    <ul>
                        <li onClick={() => props.handleSectionToggle('profile')} className={props.expandedSection === 'profile' ? 'active' : ''}>
                            <p>Профиль</p>
                        </li>
                        <li onClick={() => props.handleSectionToggle('settings')} className={props.expandedSection === 'settings' ? 'active' : ''}>
                            <p>Баптаулар</p>
                        </li>
                        <li onClick={() => props.handleSectionToggle('help')} className={props.expandedSection === 'help' ? 'active' : ''}>
                            <p>Көмек</p>
                        </li>
                        <li onClick={() => props.handleSectionToggle('feedback')} className={props.expandedSection === 'feedback' ? 'active' : ''}>
                            <p>Кері байланыс</p>
                        </li>
                    </ul>
                </div>
            }
            <div onClick={props.handleCloseMenu} className={props.isMenuOpen ? 'blur_background' : ''}>
                <main className="main_content_saves">
                    <div className="user_header">
                        <button className="menu_button" onClick={props.handleMenuClick}>
                            <img src={menuIcon} alt={userAltIcon} />
                        </button>
                        {props.photoUrl && <img src={props.photoUrl} alt="user" className="user_photo" />}
                        <h2 className="user_name">{props.displayName}</h2>
                    </div>
                </main>

                <section className="home_section">
                    <h2 className="home_section_title">Таңдаулы</h2>
                    <div className="home_section_content">
                        {props.loading ? <div className="spinner"></div> : props.favoriteBooks.map(book => <BooksCard key={book.id} name={book.title} imageUrl={book.imageUrl} price={book.price} onPlay={() => props.handleReadButtonClick(book)} />)}
                    </div>
                </section>

                <section className="home_section">
                    <h2 className="home_section_title">Сатып алынған кітаптар</h2>
                    <div className="home_section_content">
                        {props.loading ? <div className="spinner"></div> : props.userBooks.map(book => <BooksCard key={book.id} name={book.title} imageUrl={book.imageUrl} price={book.price} onPlay={() => props.handleReadButtonClick(book)} />)}
                    </div>
                </section>

            </div>

            <footer className="footer">
                {props.expandedSection === 'profile' && <div className="settings-form">
                    <div className='settings-from-left'>
                        <img src={props.photoUrl} onClick={props.handleFileUpload} alt="Profile" className="menu-profile-photo" />
                        <h2>{props.userDisplayName}</h2>
                    </div>
                    <button className="settings-logout" onClick={props.handleLogout}>Шығу</button>
                </div>}
                {props.expandedSection === 'settings' && <form className="settings-form">
                    <button
                        className="save-button"
                        type="button"
                        onClick={props.handlePayInfoClick}
                    >
                        Төлем мәліметтерін өңдеу
                    </button>
                </form>}
                {props.expandedSection === 'feedback' && <form className="settings-form" onSubmit={props.handleFeedbackSubmit}>
                    <textarea placeholder='Мәтін' className="feedback-form-textarea" value={props.feedbackText} onChange={props.handleFeedbackChange} />
                    <button className="feedback-form-submit-button" type="submit">Жіберу</button>
                </form>}
                {props.expandedSection === 'help' && <ul class="settings-form">
                    <li class="custom-list-item"><p>Сіздің KaspiGold нөміріңіз алдағы уақытта платформаның ішіндегі ақылы аудио кітаптарды сатып алу үшін қажет.</p></li>
                    <li class="custom-list-item"><p>Аудио кітапты сатып алуға ұсыныс жасағаннан кейін сізге төлем нөмірі KaspiGold-қа жіберіледі. Төлем жасағаннан кейін аудио кітап сізге 2 минут ішінде қолжетімді болады.</p></li>
                    <li class="custom-list-item"><p>Төлем жасау нөміріңізді ауыстырғыңыз келсе баптаулар бөліміне кіріңыз.</p></li>
                    <li class="custom-list-item"><p>Аудио кітаптардың оң жақ төбесіндегі белгі кітаптың ақылы екенін білдіреді.</p></li>
                </ul>}
            </footer>
            {props.isModalOpen && <Read book={props.selectedBook} closeModal={props.closeModal} />}
        </div>);
}