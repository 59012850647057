import React, { useState } from 'react';
import '../../styles/modal_sign.css';
import '../../styles/pay_modal.css';
import { doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../config';

// import PaymentRequest from '../functions/PaymentRequest';

const PayInfoModal = ({ isOpen, onClose }) => {
    const [phoneNumber, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const handleNumberSubmit = async () => {
        if (!agreeToTerms) {
            setErrorMessage('Пайдаланушы келісімшартымен келісу керек.');
            setMessage('');
            return;
        }
        try {
            if (phoneNumber) {
                const user = auth.currentUser;
                if (user) {
                    const userDocRef = doc(db, 'users', user.uid);
                    await updateDoc(userDocRef, { phoneNumber });
                    setMessage('KaspiGold нөмірі сәтті сақталды.');
                    setErrorMessage('');
                    onClose();
                } else {
                    throw new Error('Пайдаланушы анықталмады.');
                }
            } else {
                throw new Error('KaspiGold нөмірін енгізіңіз.');
            }
        } catch (error) {
            setErrorMessage(error.message);
            setMessage('');
        }
    };

    const handleSignupClick = () => setIsLogin(false);
    const handleLoginClick = () => setIsLogin(true);

    return (
        isOpen && (
            <div className="pay_modal">
                <div className='pay_modal_content'>
                    <p className="close-modal" onClick={onClose}>&times;</p>
                    <div className="form-container">
                        <div className="slide-controls">
                            <input type="radio" name="slide" id="login" checked={isLogin} readOnly />
                            <input type="radio" name="slide" id="signup" checked={!isLogin} readOnly />
                            <label htmlFor="login" className="slide login" onClick={handleLoginClick}>
                                Kaspi
                            </label>
                            <label htmlFor="signup" className="slide signup" onClick={handleSignupClick}>
                                Карта
                            </label>
                            <div className="slider-tab"></div>
                        </div>
                        <div>
                            {isLogin ? (
                                <div className="pay_modal_overlay">
                                    <input
                                        type="text"
                                        placeholder="KaspiGold нөміріңіз"
                                        className="pay_input_field"
                                        value={phoneNumber}
                                        onChange={(e) => setNumber(e.target.value)}
                                    />
                                    <div className="pay_check_content">
                                        <p className="pay_text">KaspiGold нөміріңіз сақталуына келісесіз бе?</p>
                                        <div className="pay_checkbox_container">
                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={agreeToTerms}
                                                onChange={() => setAgreeToTerms(!agreeToTerms)}
                                            />
                                            <label htmlFor="agree">
                                                <svg className="pay_check_icon" viewBox="0 0 24 24">
                                                    <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                    <button className="save_button" onClick={handleNumberSubmit}>Сақтау</button>
                                </div>
                            ) : (
                                <form className="pay_modal_overlay">
                                    <input
                                        type="text"
                                        className="pay_input_field"
                                        placeholder="1234 XXXX XXXX XXXX"
                                        // value={cardNumber}
                                        // onChange={(e) => setCardNumber(e.target.value)}
                                        required
                                    />
                                    <input
                                        type="text"
                                        placeholder="MM/YY"
                                        className="pay_input_field"
                                        // value={expiryDate}
                                        // onChange={(e) => setExpiryDate(e.target.value)}
                                        maxLength={5}
                                    />
                                    <input
                                        type="password"
                                        placeholder="CVV"
                                        className="pay_input_field"
                                        maxLength={3}
                                        // value={cvv}
                                        // onChange={(e) => setCvv(e.target.value)}
                                    />
                                    <div className="pay_check_content">
                                        <p className="pay_text">Тіркеу арқылы сіз біздің <a className="link" href='https://firebasestorage.googleapis.com/v0/b/tynda-65659.appspot.com/o/%D0%BA%D0%B0%D0%B7_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8_%D0%B4%D0%BB%D1%8F_%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BD%D0%B0_%D1%81%D0%B0%D0%B9%D1%82%D0%B5.pdf?alt=media&token=50c33b96-fb75-4b68-9d9d-222be9db7fa6'>төлем</a> шарттармен, <a className='link' href='https://firebasestorage.googleapis.com/v0/b/tynda-65659.appspot.com/o/%D0%BA%D0%B0%D0%B7_%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf?alt=media&token=1ad2f376-66f5-45e8-8440-d08c4a9befee'>құпиялылық саясатымен</a> және cookie файлдары саясатымен келісесіз.</p>
                                        <div className="pay_checkbox_container">
                                            <input
                                                type="checkbox"
                                                id="agree"
                                                checked={agreeToTerms}
                                                onChange={() => setAgreeToTerms(!agreeToTerms)}
                                            />
                                            <label htmlFor="agree">
                                                <svg className="pay_check_icon" viewBox="0 0 24 24">
                                                    <path d="M20.285,6.708l-11.4,11.4a1.5,1.5,0,0,1-2.121,0l-5.4-5.4a1.5,1.5,0,0,1,2.121-2.121L7.5,15.185,18.164,4.523a1.5,1.5,0,0,1,2.121,2.185Z" fill="currentColor" />
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="save_button">
                                        Сақтау
                                    </button>
                                </form>
                            )}
                        </div>
                        {message && <div className="pay_modal_success">{message}</div>}
                        {errorMessage && <div className="pay_modal_error">{errorMessage}</div>}
                    </div>
                </div>
            </div>
        )
    );
};

export default PayInfoModal;