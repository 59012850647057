import React, { useState } from 'react';
import '../style/HomeUI.css';

import BooksCard from '../items/BooksCard';
import Read from '../ui/Read';
import BuyModal from '../modals/BuyModal';

function HomeUI(props) {
    const [selectedBook, setSelectedBook] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPayModalOpen, setIsPayModalOpen] = useState(false);

    const handleImageClick = (book) => {
        setSelectedBook(book);
        setIsModalOpen(true);
    };

    const handleImageClickCheck = async (book) => {
        if (props.user) {
            if (props.userBooks.includes(book.id) || book.isPaid === false) {
                setSelectedBook(book);
                setIsModalOpen(true);
                props.handleReadButtonClick();
            } else {
                setSelectedBook({
                    bookId: book.id,
                    bookTitle: book.title,
                    bookDescription: book.description,
                    bookPrice: book.price,
                    audioFileNames: book.audioFileNames
                });
                setIsPayModalOpen(true);
            }
        } else {
            props.navigate('/login');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBook(null);
    };

    return (
        <main className="home_content">
            <section className="home_section">
                <p className="home_section_title">Жаңа кітаптар</p>
                <div className="home_section_content">
                    {
                        props.loading ? (
                            <div className="spinner"></div>
                        ) : (
                            props.newBooks.map(book => (
                                <BooksCard
                                    key={book.id}
                                    name={book.title}
                                    imageUrl={book.imageUrl}
                                    audioUrls={book.audioUrls}
                                    audioFileNames={book.audioFileNames}
                                    price={book.price}
                                    isPaid={book.isPaid}
                                    onImageClick={() => handleImageClickCheck(book)}
                                    onPlay={() => props.handleCheckPlay(book.id, book.audioUrls, book.audioFileNames, book.isPaid, book.title, book.description, book.price, book.author, { imageUrl: book.imageUrl })}
                                />
                            ))
                        )
                    }
                </div>
            </section>
            <section className="home_section">
                <p className="home_section_title">Жырлар</p>
                <div className="home_section_content">
                    {
                        props.loading ? (
                            <div className="spinner"></div>
                        ) : (
                            props.newSongs.map(song => (
                                <BooksCard
                                    key={song.id}
                                    id={song.id}
                                    name={song.title}
                                    imageUrl={song.imageUrl}
                                    audioUrls={song.audioUrls}
                                    audioFileNames={song.audioFileNames}
                                    onImageClick={() => handleImageClick(song)}
                                    price={song.price}
                                    onPlay={() => props.handlePlayAudio(song.id, song.audioUrls, song.audioFileNames, song.author, song.imageUrl)}
                                />
                            ))
                        )
                    }
                </div>
            </section>
            {
                props.lastBooks.length > 0 && (
                    <section className="home_section">
                        <p className="home_section_title">Жалғастыру</p>
                        <div className="home_section_content">
                            {
                                props.loading ? (
                                    <div className="spinner"></div>
                                ) : (
                                    props.lastBooks.map(song => (
                                        <BooksCard
                                            key={song.id}
                                            id={song.id}
                                            name={song.title}
                                            imageUrl={song.imageUrl}
                                            audioUrls={song.audioUrls}
                                            audioFileNames={song.audioFileNames}
                                            onImageClick={() => handleImageClick(song)}
                                            price={song.price}
                                            onPlay={() => props.handlePlayAudio(song.id, song.audioUrls, song.audioFileNames, song.author, song.imageUrl)}
                                        />
                                    ))
                                )
                            }
                        </div>
                    </section>
                )
            }
            {isModalOpen && selectedBook && (
                <Read book={selectedBook} closeModal={closeModal} />
            )}
            {isPayModalOpen && selectedBook && (
                <BuyModal
                    isOpen={isPayModalOpen}
                    onClose={() => setIsPayModalOpen(false)}
                    bookId={selectedBook.bookId}
                    bookName={selectedBook.bookTitle}
                    bookPrice={selectedBook.bookPrice}
                    bookDescription={selectedBook.bookDescription}
                    bookAudioCount={selectedBook.audioFileNames?.length || 0}
                />
            )}
        </main>
    );
}

export default HomeUI;