import React, { useState, useEffect, useRef } from 'react';
import AudioControl from '../components/AudioControl';
import ModalOptions from '../modals/ModalOptions';
import backIcon from '../../icons/back.svg';

import '../style/Read.css';

import { saveAudioTime, fetchAudioTimes } from '../functions/lastListen';
import useMediaSession from '../components/useMediaSession';

const Read = ({ book, closeModal }) => {
    const modalContentRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [currentTab, setCurrentTab] = useState('listen');
    const [savedTimes, setSavedTimes] = useState({});
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [playingAudio, setPlayingAudio] = useState(null);
    const [currentAudioIndex, setCurrentAudioIndex] = useState(null);

    const userId = JSON.parse(localStorage.getItem('user'))?.uid;

    useEffect(() => {
        const savedScrollPosition = localStorage.getItem(`scrollPosition_${book.title}`);
        if (savedScrollPosition) {
            setScrollPosition(parseInt(savedScrollPosition, 10));
        }

        const loadAudioTimes = async () => {
            const times = await fetchAudioTimes(userId, book.id);
            setSavedTimes(times || {});
        };
        loadAudioTimes();
    }, [book.title, book.id, userId]);

    useEffect(() => {
        if (modalContentRef.current) {
            modalContentRef.current.scrollTop = scrollPosition;
        }
    }, [scrollPosition]);

    const handleScroll = () => {
        if (modalContentRef.current) {
            localStorage.setItem(`scrollPosition_${book.title}`, modalContentRef.current.scrollTop);
        }
    };

    const handlePlay = (audioElement, index) => {
        if (playingAudio && playingAudio !== audioElement) {
            playingAudio.pause();
            setPlayingAudio(null);
        }
        setPlayingAudio(audioElement);
        setCurrentAudioIndex(index);
    };

    const handleTimeUpdate = (audioIndex, currentTime) => {
        saveAudioTime(userId, book.id, audioIndex, currentTime);
        setSavedTimes((prev) => ({ ...prev, [audioIndex]: { listenTime: currentTime } }));
    };

    useMediaSession({
        title: book.title,
        artist: book.author || 'Unknown Artist',
        artwork: [
            { src: book.imageWallpaper || book.imageUrl, sizes: '512x512', type: 'image/png' },
        ],
        onPlay: () => {
            if (playingAudio) playingAudio.play();
        },
        onPause: () => {
            if (playingAudio) playingAudio.pause();
        },
        onNext: () => {
            if (currentAudioIndex < book.audioUrls.length - 1) {
                const nextIndex = currentAudioIndex + 1;
                setCurrentAudioIndex(nextIndex);
                const nextAudio = document.getElementById(`audio-${nextIndex}`);
                handlePlay(nextAudio, nextIndex);
            }
        },
        onPrev: () => {
            if (currentAudioIndex > 0) {
                const prevIndex = currentAudioIndex - 1;
                setCurrentAudioIndex(prevIndex);
                const prevAudio = document.getElementById(`audio-${prevIndex}`);
                handlePlay(prevAudio, prevIndex);
            }
        },
    });

    const renderListenTab = () => {
        return (
            <div className="audio_list">
                {book.audioUrls.map((audioUrl, index) => (
                    <div
                        key={index}
                        className={`audio_item ${playingAudio?.src === audioUrl ? 'sticky-audio' : ''}`}
                    >
                        <AudioControl
                            audioUrl={audioUrl}
                            onSaveTime={(time) => handleTimeUpdate(index, time)}
                            savedTime={savedTimes[index]?.listenTime || 0}
                            id={`audio-${index}`}
                            onPlay={(audioElement) => handlePlay(audioElement, index)}
                            playingAudio={playingAudio}
                            audioFileName={book.audioFileNames[index]}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const renderSavedTab = () => {
        return (
            <div className="saved_times">
                {Object.keys(savedTimes).map((audioIndex) => (
                    <div
                        className='saved-time'
                        key={audioIndex}
                    >
                        <p>{book.audioFileNames[audioIndex]} - {savedTimes[audioIndex]?.listenTime || 0}</p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="read_modal">
            <div className="read_modal_content" ref={modalContentRef} onScroll={handleScroll}>
                <header className='read_modal_header'>
                    <img onClick={closeModal} className='close' alt='back' src={backIcon} />
                    <h2>{book.title}</h2>
                </header>
                <div className="tabs">
                    <button
                        className={currentTab === 'listen' ? 'active' : ''}
                        onClick={() => setCurrentTab('listen')}
                    >
                        Тыңдау
                    </button>
                    <button
                        className={currentTab === 'saved' ? 'active' : ''}
                        onClick={() => setCurrentTab('saved')}
                    >
                        Сақталған
                    </button>
                </div>
                <div className="image_container">
                    {book.imageWallpaper ? (
                        <img src={book.imageWallpaper} alt={book.title} className="book-image" />
                    ) : (
                        <img src={book.imageUrl} alt={book.title} className="book-image" />
                    )}
                </div>
                <div>
                    {book.description && (
                        <p className="card_description">Сипаттамасы: {book.description}</p>
                    )}
                    {book.sound && (
                        <p className="card_sound">Дыбыстаушы: {book.sound}</p>
                    )}
                </div>
                {currentTab === 'listen' && renderListenTab()}
                {currentTab === 'saved' && renderSavedTab()}
                {showOptionsModal && (
                    <ModalOptions
                        onClose={() => setShowOptionsModal(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default Read;