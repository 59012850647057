import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth, realtimeDb } from './config';
import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { ref, set, onDisconnect, get, update } from 'firebase/database';
import SignIn from './screens/ui/SignIn';
import Main from './screens/Main';
import './App.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice = /android|iPhone|iPad|iPod/i.test(userAgent);
      setIsMobile(isMobileDevice);
    };

    checkDevice();
  }, []);

  const getDeviceId = () => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = 'device-' + Math.random().toString(36).substr(2, 9);
      localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
  };

  const updateUserStatus = useCallback((user) => {
    if (user) {
      const userStatusRef = ref(realtimeDb, `status/${user.uid}`);
      const deviceId = getDeviceId();
      const isOnline = {
        state: 'online',
        last_changed: Date.now(),
        deviceId: deviceId,
      };

      const isOffline = {
        state: 'offline',
        last_changed: Date.now(),
        deviceId: null,
      };

      set(userStatusRef, isOnline);
      onDisconnect(userStatusRef).set(isOffline);
    }
  }, []);

  useEffect(() => {
    const cachedUser = localStorage.getItem('user');
    if (cachedUser) {
      setUser(JSON.parse(cachedUser));
      setLoading(false);
    } else {
      setLoading(true);
    }

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userStatusRef = ref(realtimeDb, `status/${user.uid}`);
        const snapshot = await get(userStatusRef);
        const userData = snapshot.val();
        const deviceId = getDeviceId();

        if (userData && userData.deviceId && userData.deviceId !== deviceId) {
          await update(userStatusRef, { deviceId: deviceId });
          await auth.signOut();
          localStorage.removeItem('user');
          setUser(null);
          setLoading(false);
          return;
        }

        localStorage.setItem('user', JSON.stringify(user));
      }

      setUser(user);
      setLoading(false);
      updateUserStatus(user);
    });

    const unsubscribeToken = onIdTokenChanged(auth, (user) => {
      if (!user) {
        localStorage.removeItem('user');
        setUser(null);
      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribeToken();
    };
  }, [updateUserStatus]);

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!isMobile) {
    return (
      <div className="spinner-container">
        <h1>Бұл сайт тек мобильді құрылғылар үшін қолжетімді.</h1>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Main /> : <SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;